<template>
	<div class="leftSide">
		<div class="leftSide_menu">
			<el-menu :default-active="$route.path" :default-openeds="['1', '2', '3', '4', '5', '6']">
				<el-submenu index="1">
					<template slot="title">
						<i class="el-icon-s-custom"></i>
						<span style="font-size: 16px; font-weigth: bold">{{$t("Personal Center") }}</span>
					</template>
					<el-menu-item index="1-1" @click="handlePersonalCenterList()">{{$t("My Personal Center") }}</el-menu-item>
					<el-menu-item index="1-2" @click="handleCollectionList()">{{$t("My Collection") }}</el-menu-item>
				</el-submenu>
				<el-submenu index="2" v-if="userInfo.pid === 0">
					<template slot="title">
						<i class="el-icon-s-order"></i>
						<span style="font-size: 16px; font-weigth: bold">{{$t("Order Center") }}</span>
					</template>
					<el-menu-item index="2-1" @click="handleBookingOrder()">{{$t("Pre Order") }}</el-menu-item>
					<el-menu-item index="2-2" @click="handleSalesGoods()">{{$t("My Order") }}</el-menu-item>
				</el-submenu>

				<el-submenu index="3" v-if="userInfo.pid === 0">
					<template slot="title">
						<i class="el-icon-s-comment"></i>
						<span style="font-size: 16px; font-weigth: bold">{{$t("After-Sales Service")}}</span>
					</template>
					<el-menu-item index="3-1" @click="handleAfterSalesList()">{{ $t("Application for After-Sales") }}
					</el-menu-item>
					<el-menu-item index="3-2" @click="handleAfterSalesRecord()">{{$t("After-Sales Records") }}</el-menu-item>
				</el-submenu>
				<el-submenu index="4" v-if="userInfo.pid === 0">
					<template slot="title">
						<i class="el-icon-s-goods"></i>
						<span style="font-size: 16px; font-weigth: bold">{{$t("Special Event") }}</span>
					</template>
					<el-menu-item index="4-1" @click="handleCouponsList()">{{$t("Coupons") }}</el-menu-item>
				</el-submenu>
				<el-submenu index="5" v-if="userInfo.pid === 0">
					<template slot="title">
						<i class="el-icon-document"></i>
						<span style="font-size: 16px; font-weigth: bold">{{$t("Store Business Report") }}</span>
					</template>
					<el-menu-item index="5-1" @click="handleSalesTurnover()">{{$t("Sales Turnover") }}</el-menu-item>
				</el-submenu>

				<el-submenu index="6" >
					<template slot="title">
						<i class="el-icon-share"></i>
						<span style="font-size: 16px; font-weigth: bold">{{$t("Other") }}</span>
					</template>
					<el-menu-item index="6-1" @click="handleDownloadList()">{{$t("Resources Download") }}</el-menu-item>
					<el-menu-item index="6-2" @click="handleDisplayCategory()">{{$t("Display Sample Images") }}</el-menu-item>
				</el-submenu>
			</el-menu>
		</div>
	</div>
</template>
<script>
	// import RightSide from "../rightSide";

	export default {
		components: {},
		data() {
			return {
        userInfo:{
        }
      };
		},
    created() {
      const user = window.sessionStorage.getItem("webUser");
      const userArr = JSON.parse(user);
      this.userInfo = userArr.user_info;
    },
    methods: {
			handleBookingOrder() {
				this.$router.push({
					path: "/web/orderCenter/bookingOrderList"
				});
			},
			handleSalesGoods() {
				this.$router.push({
					path: "/web/orderCenter/salesGoodsList"
				});
			},
			handleCouponsList() {
				this.$router.push({
					path: "/web/orderCenter/couponsList"
				});
			},
			handleDownloadList() {
				this.$router.push({
					path: "/web/orderCenter/downloadList"
				});
			},
			handleAfterSalesList() {
				this.$router.push({
					path: "/web/orderCenter/applyAfterSalesList"
				});
			},
			handlePersonalCenterList() {
				this.$router.push({
					path: "/web/orderCenter/personalCenter"
				});
			},
			handleAfterSalesRecord() {
				this.$router.push({
					path: "/web/orderCenter/afterSalesRecord"
				});
			},
			handleSalesTurnover() {
				this.$router.push({
					path: "/web/orderCenter/salesTurnover"
				});
			},
			handleCollectionList() {
				this.$router.push({
					path: "/web/orderCenter/collectionList"
				});
			},
      handleDisplayCategory(){
        this.$router.push({path:"/web/orderCenter/displaycatagory"})
      }
		},
	};
</script>
<style lang="scss" scoped>
	.leftSide {
		margin-top: 15px;

		&_menu {
			// height: 786px;
			background-color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}
</style>
